$(document).ready(function() {
  $(document).on("change", "#passRejectReasonId", function() {
    var value = $("#passRejectReasonId").val();
    if (value == "-1") {
      $("#passOthersRejectResonContainer").removeClass("d-none");
    } else {
      $("#passOthersRejectResonContainer").addClass("d-none");
    }
  });
});
